import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import './tier-list.scss';
import { Accordion, Button, Card } from 'react-bootstrap';
import {
  faAnglesDown,
  faArrowDown,
  faArrowUp,
  faAsterisk,
  faBriefcaseMedical,
  faFlask,
  faHandFist,
  faStar,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import {
  faFaceSadTear,
  faStar as faStarEmpty
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IHSRCharacter } from '../../modules/common/model/graphql-types';
import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import Switch from 'react-switch';
import { CustomPopover } from '../../modules/cs/common/components/custom-popover';

interface IHSRCharacterNodes {
  nodes: IHSRCharacter[];
}

interface IHSRCharacterEntry {
  allCharacters: IHSRCharacterNodes;
}

interface IProps {
  data: IHSRCharacterEntry;
}

const HSRTierListPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [eidolonSpecial, setEidolonSpecial] = useState(true);
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4★', value: '4' },
          { label: '5★', value: '5' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_physical.png"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_fire.png"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Ice',
            tooltip: 'Ice',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_ice.png"
                width={24}
                alt="Ice"
              />
            )
          },
          {
            value: 'Lightning',
            tooltip: 'Lightning',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_lightning.png"
                width={24}
                alt="Lightning"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_wind.png"
                width={24}
                alt="Wind"
              />
            )
          },
          {
            value: 'Quantum',
            tooltip: 'Quantum',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_quantum.png"
                width={24}
                alt="Quantum"
              />
            )
          },
          {
            value: 'Imaginary',
            tooltip: 'Imaginary',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_imaginary.png"
                width={24}
                alt="Imaginary"
              />
            )
          }
        ]
      },
      {
        key: 'path',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Abundance',
            tooltip: 'Abundance',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_abundance.png"
                width={24}
                alt="Abundance"
              />
            )
          },
          {
            value: 'Destruction',
            tooltip: 'Destruction',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_destruction.png"
                width={24}
                alt="Destruction"
              />
            )
          },
          {
            value: 'Erudition',
            tooltip: 'Erudition',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_erudition.png"
                width={24}
                alt="Erudition"
              />
            )
          },
          {
            value: 'Harmony',
            tooltip: 'Harmony',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_harmony.png"
                width={24}
                alt="Harmony"
              />
            )
          },
          {
            value: 'Hunt',
            tooltip: 'Hunt',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_hunt.png"
                width={24}
                alt="Hunt"
              />
            )
          },
          {
            value: 'Nihility',
            tooltip: 'Nihility',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_nihility.png"
                width={24}
                alt="Nihility"
              />
            )
          },
          {
            value: 'Preservation',
            tooltip: 'Preservation',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_preservation.png"
                width={24}
                alt="Preservation"
              />
            )
          },
          {
            value: 'Remembrance',
            tooltip: 'Remembrance',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_remem.png"
                width={24}
                alt="Remembrance"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (sortOption === 'moc' && eidolonSpecial === false) {
      setSortOption('moc_special');
    }

    if (sortOption === 'moc_special' && eidolonSpecial === true) {
      setSortOption('moc');
    }

    if (sortOption === 'pure' && eidolonSpecial === false) {
      setSortOption('pure_special');
    }

    if (sortOption === 'pure_special' && eidolonSpecial === true) {
      setSortOption('pure');
    }

    if (sortOption === 'apo' && eidolonSpecial === false) {
      setSortOption('apo_special');
    }

    if (sortOption === 'apo_special' && eidolonSpecial === true) {
      setSortOption('apo');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.path && activeFilters.path !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.path.toLowerCase() === activeFilters.path.toLowerCase()
        );
      }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption, eidolonSpecial]);

  const sortingOptions = [
    { value: 'moc', label: 'Memory of Chaos' },
    { value: 'pure', label: 'Pure Fiction' },
    { value: 'apo', label: 'Apocalyptic Shadow' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_tierlist.png"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Tier list (December 2024)</h1>
          <h2>
            The most popular tier list for Honkai: Star Rail that rates all
            available characters by their performance in Memory of Chaos, Pure
            Fiction and Apocalyptic Shadow.
          </h2>
          <p>
            Last updated: <strong>04/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      {/* <div className="afk-ad-banner">
        <div className="cta">
          <h4>New Season "Waves of Intrigue" is now live in AFK Journey</h4>
          <p>Unveil the mysteries of Rustport and uncover new adventures!</p>
          <OutboundLink href="https://bit.ly/PrydwenHSR" target="_blank">
            <Button variant="primary">Download Now</Button>
          </OutboundLink>
        </div>
      </div> */}
      <div className="tier-list-page">
        <SectionHeader title="Honkai: Star Rail Tier list" />
        <Accordion defaultActiveKey="0" className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the Tier List</Accordion.Header>
            <Accordion.Body>
              <p>
                Please keep in mind that Honkai: Star Rail{' '}
                <strong>is a game where team building matters most</strong> and
                while our tier list takes the optimal setup into account, a lot
                of characters can work and do well - even those ranked lower -
                when you invest into them. Also for story or lower difficulties
                of Simulated Universe, you don't need to worry about ratings and
                tiers. You can safely clear that content even with F2P
                characters as long as you build your team correctly.
              </p>
              <p>
                These tier lists rate characters based on their average
                performance in{' '}
                <strong>
                  Memory of Chaos, Pure Fiction and Apocalyptic Shadow
                  regardless of turbulence, whimsicality and cacophony
                </strong>
                . Characters rated higher will perform well without the need to
                rely on these and will only benefit more from receiving them. As
                new mechanics, characters and challenges are introduced each
                tier list will be updated.
              </p>
              <p>
                <strong>
                  Important! Characters are ordered alphabetically within a tier
                </strong>
                .
              </p>
              <p>Available tier lists:</p>
              <ul>
                <li>
                  <strong>Memory of Chaos (MoC)</strong> - how the character
                  performs in the Memory of Chaos. Blast and single target
                  damage are important here while AoE has niche uses against
                  some bosses.
                </li>
                <li>
                  <strong>Pure Fiction (PF)</strong> - how the character
                  performs in the Pure Fiction. AoE is king here, followed by
                  Blast while single target damage is mostly useless.
                </li>
                <li>
                  <strong>Apocalyptic Shadow (AS)</strong> - how the character
                  performs in the Apocalyptic Shadow. Single target and Break
                  potential are heavily favored here.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Categories & Tags</Accordion.Header>
            <Accordion.Body>
              <h6>Categories</h6>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">Damage Dealer</strong> - Characters
                  that focus on dealing direct damage and can perform their role
                  unhindered by enemy type, enemy element or team composition,
                </li>
                <li>
                  <strong className="specialist">Specialist</strong> -
                  Characters that excel under specific circumstances carrying a
                  unique role that often requires a different and divergent
                  playstyle,
                </li>
                <li>
                  <strong className="amplifier">Amplifier</strong> - Characters
                  that do not focus on dealing damage but instead amplify the
                  damage other teammates deal by granting allies buffs or
                  debuffing enemies,
                </li>
                <li>
                  <strong className="sustain">Sustain</strong> - Characters who
                  are capable of keeping the team alive with their abilities.
                </li>
              </ul>
              <h6>Special tags</h6>
              <p>
                Tags are split into 3 categories:{' '}
                <strong className="positive">Pros</strong>,{' '}
                <strong className="negative">Cons</strong> and{' '}
                <strong className="archetype">Archetype</strong> which represent
                the most defining features of a character's kit and the current
                meta archetypes they fit into. Archetype tags indicate a
                connection between characters with that tag e.g. Acheron
                requires debuffs to work and Silver Wolf applies them. Here is a
                full list of all current tags and what they mean:
              </p>
              <ul>
                <li>
                  <strong className="archetype">Break</strong> - the character
                  belongs to the Break archetype focusing on Break Effect and
                  Break Efficiency,
                </li>
                <li>
                  <strong className="archetype">Debuff</strong> - the character
                  belongs to the Debuff archetype applying one or many debuffs
                  or has a kit that directly relies on them to function,
                </li>
                <li>
                  <strong className="archetype">DoT</strong> - the character
                  belongs to the Damage-over-Time archetype specializing in
                  applying and/or amplifying DoT effects,
                </li>
                <li>
                  <strong className="archetype">FuA</strong> - the character
                  belongs to the Follow-up Attack archetype benefitting from,
                  buffing or delivering powerful Follow-Up Attacks.,
                </li>
                <li>
                  <strong className="archetype">Summon</strong> - the character
                  fights alongside summons or other entities,
                </li>
                <li>
                  <strong className="positive">Advance</strong> - the character
                  can manipulate the turn order by either action advancing their
                  allies or themselves,
                </li>
                <li>
                  <strong className="positive">Buff</strong> - the character can
                  apply various Buffs to help their teammates,
                </li>
                <li>
                  <strong className="positive">Delay</strong> - the character
                  can delay enemies and hinder their actions in battle,
                </li>
                <li>
                  <strong className="positive">Energy</strong> - the character
                  can recover the teammates energy,
                </li>
                <li>
                  <strong className="positive">SP-Friendly</strong> - the
                  character is substantially more skill point friendly than
                  other characters in their role.
                </li>
                <li>
                  <strong className="negative">SP-Unfriendly</strong> - the
                  character is substantially more skill point un-friendly than
                  other characters in their role.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Ratings & Meta Lines</Accordion.Header>
            <Accordion.Body>
              <h5>Meta Lines</h5>
              <p>
                Meta Lines categorize each section of the tier list into a power
                bracket. Here are the four brackets:
              </p>
              <ul>
                <li>
                  <strong className="tier-05">
                    Apex Characters (T0 & T0.5)
                  </strong>{' '}
                  - characters in this bracket are the cream of the crop. They
                  can do outrageous amount of damage, provide massive buffs (or
                  debuffs) or keep the team alive no matter what the enemy
                  throws at them. They simply can achieve the best, most
                  consistent and accessible results in their best teams,
                  allowing you to easily clear the content,
                </li>
                <li>
                  <strong className="tier-2">
                    Meta Characters (T1, T1.5, T2)
                  </strong>{' '}
                  - characters in this bracket make clearing the content a
                  breeze, but compared to the Apex characters they require more
                  work from your side. Either their team options are more
                  restrictive, they require bigger Relic investment or they
                  possess a notable weakness or downside. Still, they are all
                  great and won't disappoint you if you give them what they need
                  to shine,
                </li>
                <li>
                  <strong className="tier-4">Niche Characters (T3 & T4)</strong>{' '}
                  - characters in this bracket are lacking in one or many fields
                  of their role. These characters still possess the ability to
                  clear all content with the right build, endgame blessing, team
                  or enemy line-up but have much lower play rates and success
                  rates than Meta and Apex characters,
                </li>
                <li>
                  <strong className="tier-5">The Forgotten Ones (T5)</strong> -
                  characters in this bracket struggle. A lot. Either their kits
                  simply don't work in the mode or they require a ton of
                  investment and love to be able to work. Just avoid them unless
                  your husbando or waifu landed here and you believe in the
                  power of love.
                </li>
              </ul>
              <h5>Half tiers</h5>
              <p>
                To further define the tier list and correctly represent the
                power level of each character in relation to each other we have
                added half tiers to the higher tier brackets. Under our previous
                system a tier rating jump of 1 tier rating made it difficult to
                position characters accurately, often leading to multiple
                characters ending up in the same tier despite a clear power and
                utility difference.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Criteria</Accordion.Header>
            <Accordion.Body>
              <h6>Criteria</h6>
              <ul>
                <li>Manual play,</li>
                <li>Using optimal team composition/support characters,</li>
                <li>Character level 80,</li>
                <li>
                  All Major Traces unlocked and skills upgraded to level 8,
                </li>
                <li>
                  Character equipped with level 80{' '}
                  <strong>non-limited 4★ or 5★ Light Cone</strong> (including
                  the 'standard' Light Cones),
                </li>
                <ul>
                  <li>
                    Battle Pass Light Cones aren't taken into account because
                    you can't obtain them without spending,
                  </li>
                </ul>
                <li>
                  Character equipped with 5★ upgraded gear with optimal Main
                  Stats and Sub Stats (with 24 desired sub stats out of a
                  possible 54),
                </li>
                <li>Eidolon level 0 for 5★ characters,</li>
                <li>Eidolon level 0/6 for 4★ characters.</li>
              </ul>
              <h6>Roles</h6>
              <p>
                We compare characters within their role - so damage dealers are
                compared to other damage dealers and the ratings are adjusted
                based on their performance within the role. Don't try to compare
                characters between roles (for example an amplifier with a damage
                dealer), because their ratings mean different things.
              </p>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">Damage Dealer</strong> - Rating is
                  heavily influenced based on the characters ability to deal
                  enough damage to achieve the 3-star objective, the easier and
                  more quickly achieved the higher the rating,
                </li>
                <li>
                  <strong className="specialist">Specialist</strong> - Rating is
                  heavily influenced based on the damage they provide, defense
                  or efficiency boost created by the unique teams these
                  characters create or the utility they provide against certain
                  encounters,
                </li>
                <li>
                  <strong className="amplifier">Amplifier</strong> - Rating is
                  influenced based on the damage amplification abilities of the
                  character and the utility they provide to the team,
                </li>
                <li>
                  <strong className="sustain">Sustain</strong> - Rating is
                  influenced based on the characters ability to keep the team
                  alive and the utility they provide. The more utility and
                  higher the safety, the higher the rating.
                </li>
              </ul>
              <h6>Other criteria affecting the ratings</h6>
              <p>
                Each tier list rates characters based on their average value in
                the specific game mode it represents.{' '}
                <strong>
                  The higher a character is rated on the tier list the greater
                  their power and more widely they can be used effectively in
                  the mode
                </strong>
                . We consider:
              </p>
              <ul>
                <li>
                  <strong>Flexibility</strong> - how the characters kit
                  functions against various enemy types, amounts of enemies and
                  their ability to perform when not fighting against enemies
                  vulnerable to their element,
                </li>
                <li>
                  <strong>Investment</strong> - some characters require specific
                  limited 5★ units, heavy investment in certain relic sets or an
                  abundance of sub-stats in order to function at the highest
                  level. Each of these points impacts the rating in a negative
                  way. - the more the character requires to perform well, the
                  bigger the impact,
                </li>
                <li>
                  <strong>Utility</strong> - recently released characters
                  possess abilities that lie beyond their defined role, for
                  example Amplifiers/Sustains that can deal decent damage. The
                  more a character helps the team outside of their role, the
                  bigger positive impact on their rating it has.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Changelog
              {/* <span className="tag new">Updated!</span> */}
            </Accordion.Header>
            <Accordion.Body>
              <h6>
                <strong>04/12/2024</strong>
              </h6>
              <p>
                <HSRCharacter mode="inline" slug="sunday" enablePopover />{' '}
                arrival to the game is more impactful than most characters as he{' '}
                <strong>
                  outperforms the other Hypercarry Amplifiers in most of their
                  top teams
                </strong>{' '}
                and also has room to gain even more meta relevance the moment
                more summoning characters are released (so 3.0). The result of
                this is a need for us to reorganize the Amplifier category to
                better display the new 'pecking order' (please no more Charmony
                Dove brainrot) into the meta, re-evaluate every character who
                can benefit from Sunday and make updates to their ranking
                accordingly. Here's what happened:
              </p>
              <ul>
                <li>
                  <strong>Amplifier Reshuffle</strong>:{' '}
                  <HSRCharacter mode="inline" slug="sparkle" enablePopover />{' '}
                  and <HSRCharacter mode="inline" slug="bronya" enablePopover />{' '}
                  are the two characters that are most impacted by the release
                  of Sunday. Both are interchangeable with him and in many cases
                  also beaten by him (more like executed) in some of their top
                  teams. With this in mind, we’ve adjusted Bronya, Sparkle and a
                  number of other Amplifier rankings according to the power
                  increase Sunday introduces.
                </li>
                <li>
                  <strong>Sunday & Damage Dealers</strong>: Sunday provides a
                  power increase to many damage dealers but one character in
                  particular gains far far more than others - Our General{' '}
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />.
                  Sunday isn’t just a simple damage increase for Jing Yuan -
                  he's straight up a kit overhaul for him, fixing many of his
                  flaws and increasing his performance in all 3 endgame modes
                  dramatically. We usually stick to only making additions on new
                  character releases unless they overlap with new phase data but
                  Sunday and Jing Yuan are an exception - the power of the combo
                  is undeniable and thus needs an immediate update on all three
                  lists. List of changes to Jing Yuan ratings:
                </li>
                <ul>
                  <li>
                    <strong>Memory of Chaos</strong> - T2{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T1.
                  </li>
                  <li>
                    <strong>Pure Fiction</strong> - T2{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T0.
                  </li>
                  <ul>
                    <li>
                      Yes, we are aware that there are too many DPS characters
                      in T0 Pure Fiction now, but that's the cost of the
                      'emergency patch'. We will make adjustments when the next
                      Pure Fiction drops as the mode is receiving a rework
                      either way, so quite a lot things will change most likely.
                    </li>
                  </ul>
                  <li>
                    <strong>Apocalyptic Shadow</strong> - T2{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T0.5.
                  </li>
                </ul>
                <li>
                  <strong>Sunday Moving Forward:</strong> Sunday comes out of
                  the gates swinging - he's able to support most characters in
                  the game to some degree and even finds a place as best in slot
                  in some of their teams. With that said, Sunday definitely
                  isn’t at his maximum potential yet - he is a character that
                  specialises in advancing and buffing Summoners and right now
                  there is only really one non-supportive option Jing Yuan.
                  Based on this we’re not placing Sunday in Tier 0 at the time
                  of his release but we very well expect him to make his way
                  there soon...
                </li>
              </ul>
              <p>
                <strong>MoC Tier List update</strong>
              </p>
              <p>
                Alongside Sunday’s release we’re also updating the MoC tier list
                based on the most recent phase:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="lingsha" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Lingsha continues to dominate the endgame meta, offering
                  tremendous offensive power while ticking all the boxes a top
                  tier sustain needs at the same time. Because of that we're
                  raising her to T0.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="huohuo" enablePopover /> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Huohuo still serves as one of the top sustains of MoC
                  but isn’t quite performing at the level of Lingsha or
                  Aventurine. That's why we’re lowering her a tier for now based
                  on their dominance.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="dr-ratio" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. The hyperinflation of HP that's happening in the last few
                  phases wasn't kind to Dr. Ratio and for the first time since
                  his release, he's showing signs of fatigue. While his single
                  target damage output is still good, he's struggling to kill
                  things fast enough and 'spreading' his chalk damage around
                  when there's multiple chunky enemies he has to deal with -
                  even with the full power of the FUA team behind his back.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jingliu" enablePopover />{' '}
                  T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2 and{' '}
                  <HSRCharacter mode="inline" slug="seele" enablePopover /> &{' '}
                  <HSRCharacter mode="inline" slug="qingque" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Each of these characters is suffering from power creep,
                  being pushed out by enemy and encounter designs not suited to
                  their kits. As a result, both their usage rates and
                  performance suffers.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="lynx" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Lynx isn't really played with Yunli as often as we thought
                  she would and also the increase of difficulty showcased a
                  fatal flaw in her kit - her healing just isn't enough and she
                  often gets the team killed.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="misha" enablePopover /> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Misha Who?
                </li>
              </ul>
              <p>
                We also added{' '}
                <HSRCharacter mode="inline" slug="rappa" enablePopover /> and{' '}
                <HSRCharacter mode="inline" slug="jing-yuan" enablePopover /> to
                the Watchlist for MoC:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="rappa" enablePopover /> has
                  performed significantly above her current tier list placement
                  in this phase of MoC. With that said we feel this MoC is
                  uniquely tailored towards her strengths - we want to see Rappa
                  put in a similar performance in one more phase before
                  potentially moving her into the Apex tiers.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
                  - Sunday has increased Jing Yuan’s power substantially - based
                  on this in MoC specifically he has jumped 2 tiers all at once.
                  But in addition to this we’re also "watchlisting" him - the
                  current MoC 12 side 1 while Lightning-weak, carries a massive
                  amount of Crowd Control. Something that makes evaluating the
                  true power of Jing Yuan and Sunday consistently challenging.
                  We want to see more from Sunday and Jing Yuan before placing
                  him any higher on the MoC tier list, so we’ll revisit him in
                  MoC with next phases data.
                </li>
              </ul>
              <h6>
                <strong>18/11/2024</strong>
              </h6>
              <p>
                In our previous Pure Fiction update we marked a number of
                characters with the potential to move up or down on the tier
                list (our watchlist). With another round of data, testing and
                community feedback we’re following through on all these changes:
              </p>
              <ul>
                <li>
                  <strong>[Jiaoqiu]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Since his release, Jiaoqiu is one of the highest
                  performers in Pure Fiction and in this phase he has continued
                  his dominant performance. While previously he was mostly used
                  with Acheron (and he elevated her to new heights) in this
                  phase he's also being successfully played with Kafka/BS (in
                  DoT teams) but also with Himeko (Crit version). We believe
                  he’s earned his place in T0 because of that.
                </li>
                <li>
                  <strong>[Feixiao]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. A Hunt character being so high in PF? What is this
                  blasphemy. No, we didn't lose our minds (at least we hope so)
                  as Feixiao is being played in this mode as an enabler for
                  Herta and Himeko - both characters rely on enemies being often
                  broken and Feixiao can easily do that, procing the kurukuru /
                  nigasanai wa super often.
                </li>
                <li>
                  <strong>[Seele]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. As someone wise said, balance must be kept - one Hunt unit
                  has risen in the ranks and another has to drop. Kidding aside,
                  Seele has continued to remain a niche choice outside of
                  Shatter being present in the Pure Fiction Phase. We’re
                  lowering her rating by a tier to reflect this.
                </li>
                <li>
                  <strong>[Jingliu]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Jingliu continues to face challenges in Pure Fiction this
                  phase. As outlined previously we’re lowering her down a tier
                  and now she's entered the Niche category.
                </li>
              </ul>
              <p>Other Pure Fiction changes:</p>
              <ul>
                <li>
                  <strong>[Yunli]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Yunli continues to surprise us. She is able to stand on
                  her own (hypercarry teams) but is even stronger when combined
                  with other synergistic damaging characters (dual DPS teams
                  with Feixiao, Himeko, and Jade) making her incredibly
                  flexible. Despite being used in a myriad of teams, Yunli
                  boasts some of the highest average scores of the mode -
                  regardless of the Phase buff she always delivers. Flexibility,
                  high performance and consistency - based on this we’re raising
                  her to Tier 0.
                </li>
                <li>
                  <strong>[Acheron]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Since the release of Jiaoqiu, Acheron performance in Pure
                  Fiction drastically improved. The Foxian fixes most of her
                  issues, allowing her to reach new heights in this and other
                  modes - regardless of the Phase-specific buffs. Because of the
                  shown consistency, we're raising her to T0.
                </li>
                <li>
                  <strong>[Ruan Mei]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Ruan Mei is the queen of diversity and to this day retains
                  one of the highest use rates in all modes. The release of
                  Rappa grants her one more, especially potent top tier team
                  specifically in her Break-focused niche. With a stronger
                  top-end on her teams and continued widespread use we’re
                  raising her back to Tier 0 alongside Robin. The data might not
                  always be on Ruan Mei’s side, due to her being used in such a
                  wide variety of teams but there is no doubt she’s part of
                  multiple of the strongest Pure Fiction teams right now.
                </li>
                <li>
                  <strong>[Gepard]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T2. While his younger, gambling-addicted brother, is the king
                  of Shielding, Gepard has shown great performance in the last
                  few phases - those who don't have Aventurine are finding
                  success with Gepard once he's properly build. We're raising
                  him from the depths of Niche tier because of that.
                </li>
                <li>
                  <strong>[Serval]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T3. Serval has seen a uptick in performance, but not big
                  enough to raise her from the Niche tier. Still, at least
                  compared to a lot characters - even those limited ones - she
                  actually sees some usage and somehow works.
                </li>
                <li>
                  <strong>[Argenti] & [Himeko]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Double whammy! Himeko and Argenti are without a doubt
                  two of the strongest Pure Fiction characters in certain
                  phases, landing them at the top of the tier list (and in our
                  data) on many occasions. But at the same time, both of them
                  have phases when they struggle and even fail to reach 30k -
                  usually phases where the enemy weaknesses do not favor their
                  element. We expect these characters to continue to be a hot
                  topic, but we will be lowering their ranking for now based on
                  their lacking performance in the last 3 phases (so what's
                  being taken into account for the purpose of the tier list).
                </li>
                <li>
                  <strong>[Bronya]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Tingyun and Pela both see frequent and competitive play
                  in Pure Fiction, popping up in a variety of team compositions
                  capable of scoring very well. We feel Bronya doesn’t quite fit
                  alongside these two in the current state of endgame in terms
                  of performance or flexibility - usually being a 2nd, 3rd or
                  even 4th pick support in most high scoring teams. For these
                  reasons we’re moving her down a tier.
                </li>
                <li>
                  <strong>[Asta] & [Hanya]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Another double whammy! Asta and Hanya’s play rate and
                  performance has been in steady decline with the introduction
                  of new units and higher difficulty in Pure Fiction. This
                  combined with Bronya moving down to Tier 1.5 is the reason
                  we’ll be moving both down a tier.
                </li>
                <li>
                  <strong>[Qingque]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. Another bad phase for our Gamba Gremlin. Her damage just
                  isn't enough anymore and we're dropping her down a tier to
                  better reflect her place in the meta.
                </li>
                <li>
                  <strong>[Lynx]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. With the drastic increase of difficulty in the last phase,
                  Lynx issues were exposed. She struggles to keep the team alive
                  and the additional benefits she brings aren't enough to
                  justify using her other other Sustains.
                </li>
                <li>
                  <strong>[Trailblazer Fire]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. We have recently moved Fire MC to Sustains (from
                  Specialist) and we're adjusting their rating to better reflect
                  their strength.
                </li>
              </ul>
              <h6>
                <strong>08/11/2024</strong>
              </h6>
              <p>
                We also made several changes to the Apocalyptic Shadow tier list
                and here's what has changed:
              </p>
              <ul>
                <li>
                  <strong>[Robin]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. The FUA archetype took the meta by storm, reaching the
                  heights of the Super Break archetype and in some cases, even
                  outperforming it. Robin is the key member of the FUA
                  archetype, so placing her anywhere but in the T0 makes zero
                  sense - that's why we have raised her where she belongs.
                </li>
                <li>
                  <strong>[Bronya]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Bronya fall from the meta and her resurgence deserves to
                  be studied. While initially, Sparkle outperformed her in most
                  teams, the tables has been turned (or rather flipped) and
                  Bronya now has more meta DPS that she is commonly paired with
                  (Boothil, Feixiao and to lesser degree Acheron), drastically
                  improving her performance in Apocalyptic Shadow (and in other
                  modes).
                </li>
                <li>
                  <strong>[Imbibitor Lunae]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. The E0 Cooler Daniel is struggling. A lot. While his
                  damage output is still decent, it pales in comparison to what
                  Feixiao, Boothill, Firefly and Yunli can do. Adding the SP
                  issues on top of that, paints a sad picture for our Dragon who
                  struggles to keep up with other damage dealers and whose
                  performance continues to drop.
                </li>
                <li>
                  <strong>[Seele]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Similar to Imibibtor Lunae, Seele doesn't have a good time
                  in Apocalyptic Shadow. Her unique mechanic doesn't work that
                  well there, which lead to plummeting performance and usage
                  rates in the last few phases and we have decided to drop her
                  down a tier because of that.
                </li>
                <li>
                  <strong>[Qingque]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. The Gamba Gremlin also struggles. Her multipliers aged
                  badly and even if the RNG is on your side, the amount of
                  damage and breaking she brings to the table is not enough in
                  AS to make her worth using and she only shines where the phase
                  buff lends her a hand. Because of that we're dropping her to
                  the Niche category.
                </li>
                <li>
                  <strong>[Misha] & [Hook]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Bad damage, bad breaking, bad performance. That's a short
                  summary for Misha and Hook struggles in Apocalyptic Shadow and
                  the reason why they were dropped to the Forgotten tier.
                </li>
                <li>
                  <strong>[Black Swan] & [Kafka]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. DoT continues to disappoint in every mode and it's time
                  to drop them again in Apocalyptic Shadow. Their damage just
                  isn't there and they can't break stuff fast enough to make
                  them worth using in this mode over other characters. Hoyo, we
                  beg you - do something nice for DoTs!
                </li>
                <li>
                  <strong>[Xueyi]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Xueyi simply isn't used when there's no Quantum weakness
                  and compared to other characters who sit in T1.5 she stands
                  out as the weakest 'link', so we have decided to drop her down
                  a tier to better reflect her position in the current meta.
                </li>
                <li>
                  <strong>[Silver Wolf]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Despite the Implant issues being fixed in Apocalyptic
                  Shadow, Silver Wolf simply lacks good teams where she can be
                  used. Mono Quantum is nearly as dead as DoTs and outside of
                  Quantum teams, the Implant isn't enough and it's hard to
                  justify using her over other Amplifiers that bring way way
                  more to the table. Also, with the game being older, people
                  have more DPS build (allowing for better elemental coverage),
                  so Silver Wolf isn't as important as she was in the past.
                </li>
                <li>
                  <strong>[Yukong]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Well, the fox is dead as she's overshadowed by virtually
                  every other Amplifier in the game. She requires way too much
                  effort to honestly barely being able to do what others in her
                  role can do and similar to other modes, we're dropping her to
                  the Forgotten tier.
                </li>
                <li>
                  <strong>[Gallagher]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Lingsha has proven to be way stronger than we
                  anticipated and after doing further testing, we have decided
                  to drop Gallagher down half a tier. If you look strictly as
                  Sustain potential, they are roughly equal, but Lingsha brings
                  so much more breaking and damage to the table, it's not even
                  funny - and those two are very important in AS. She's
                  basically an Erudition unit in disguise who also is strong
                  enough to keep the team alive even if you build her pure
                  damage. Wait, this was supposed to be changelog for Gallagher,
                  not another praise post for Lingsha. Overall Gallagher is
                  still a great character, but Lingsha just offers MORE so
                  keeping them in the same tier in Apocalyptic Shadow doesn't
                  make sense.
                </li>
                <ul>
                  <li>
                    We're also looking into Aventurine as Lingsha is just too
                    good in AS, but we need more data and do more tests before
                    considering having Lingsha as the only T0 Sustain in this
                    mode.
                  </li>
                </ul>
                <li>
                  <strong>[Fu Xuan] and [Huohuo]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. The victims of Gallagher dropping to T0.5. If you look at
                  the data for the last 3 phases (so what's considered for the
                  purpose of the tier list), Fu Xuan and Huohuo are closer to
                  Luocha's performance than to that of Gallagher. But looking
                  past the data, both are second or even third choice in the two
                  reigning archetypes (FUA and Break) as they simply don't bring
                  enough things to make them first choice. Huohuo energy regen
                  isn't needed there (but Yunli loves it) and Fu Xuan also often
                  gets one-shot by massive AoE attacks or an onslaught of
                  smaller ones (since enemies Speeds continue to increase) and
                  the 12% Crit Rate (fixed!) just isn't that enticing as it was
                  in the past. They are still strong sustains, but the meta
                  favors the 3 remaining Apex sustains more than them currently.
                  Though, things can change in the future and if they will, we
                  will revisit their Apex placement in AS.
                </li>
              </ul>
              <h6>
                <strong>23/10/2024</strong>
              </h6>
              <p>
                <strong>[Rappa]</strong> has been added to the tier list.
              </p>
              <p>
                We also made several changes to the MoC tier list and here's
                what has changed:
              </p>
              <ul>
                <li>
                  <strong>[Acheron]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Despite the release of Jiaoqiu who made Acheron much
                  better, when comparing Acheron teams to Feixiao teams, Acheron
                  falls a bit short. To be clear Acheron isn’t weak. But Feixiao
                  has proven she's on another level, not in just one, but
                  multiple teams allowing her to clear MoC stages effortlessly -
                  even without her best support, Robin, you can still use
                  Bronya, if you don't have Topaz, you can use Moze or Hunt
                  March. Acheron has options as well but those options aren’t as
                  potent as Feixiao - on the top-end, her best team alongside
                  Jiaoqiu can’t reach the heights Feixiao can and on the
                  lower-end, Feixiao only presses the advantage. In the future
                  when/if Acheron receives more teammates we feel her team
                  ceiling and floor can increase, but currently, Feixiao has won
                  the battle of supremacy and will be the sole T0 for now.
                </li>
                <li>
                  <strong>[Jingliu]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Compared to other damage dealers, Jingliu didn't receive
                  any new teammates that would allow her to perform at higher
                  level for quite some time. Furthermore, hitting for 200k was
                  insane back in the day, but when playing the newer and shinier
                  damage dealers, seeing those numbers makes you think you build
                  the character wrong. Because of this, her performance in MoC
                  continues to drop as her damage output simply isn't enough to
                  deal with the ever-increasing difficulty of MoC.
                </li>
                <li>
                  <strong>[Seele]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. The HP of enemies continues to increase with every new
                  phase and even the well geared Seeles are starting to struggle
                  to continuously trigger Resurgence. The enemies that we're
                  fighting recently in MoC (most of the Penacony ones) also
                  don't help with the core mechanic of Seele and both her usage
                  rate and average performance reflect that, so we have decided
                  to drop her down half a tier.
                </li>
                <li>
                  <strong>[Kafka]</strong> & <strong>[Black Swan]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Life is not good for DoT fans. Kafka and Black Swan's
                  damage output isn't good enough anymore and the DoT procs
                  tickle the enemy rather than doing any meaningful damage. This
                  lead to a big drop in both performance and usage rates in the
                  last few phases as there simply are better options to run.
                  They really need a dedicated DoT support to make them viable
                  again. Hoyo, please make DoTs great again - we beg you!
                </li>
                <li>
                  <strong>[Xueyi]</strong> & <strong>[Luka]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Both shine bright when the MoC phase features enemies
                  matching their element and a buff which benefits them. Outside
                  of a strong phase here and there, they routinely fade into
                  obscurity shortly after. This fits our definition of the Niche
                  category to a tee - because of this we feel both characters
                  are more accurately placed not above the META line but at the
                  top of the NICHE line.
                </li>
                <li>
                  <strong>[Trailblazer Fire]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Outside of some very niche uses in Acheron teams, the Fire
                  version of the MC sees very little play. Part of this is their
                  losing battle against the much stronger, and popular Imaginary
                  version and part of it is a lack of a solid niche. Because of
                  this we're dropping them to the Forgotten tier.
                </li>
                <li>
                  <strong>[Sparkle]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. Similar to DoTs, the hypercarry archetype struggles to
                  compete with Break and FUA in the current meta. From the top
                  damage dealers, Sparkle is mainly paired with Yunli and
                  Acheron (and even there she can be replaced) and outside of
                  them, none of the characters she is played with are considered
                  meta as they struggle to perform well in MoC. Because of this,
                  we think Sparkle isn't Apex tier material anymore and we're
                  dropping her to T1.
                </li>
                <li>
                  <strong>[Yukong]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Yukong who? Jokes aside, Yukong simply has no place in the
                  current meta and even Imbibitor Lunae doesn't use her anymore,
                  so we've decided to drop her to the Forgotten tier.
                </li>
                <li>
                  <strong>[Blade]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Blade's average performance in the last few phases
                  surprised us. He's being commonly used in dual DPS teams with
                  Yunli, Feixiao, Clara and Jade, but outside of that there are
                  even some hypercarry teams using Blade. Blade damage isn't as
                  good as it was back in the day, but he makes up for it with
                  the frequency of his attacks which help with breaking enemies
                  in AoE scenarios (plus he has a FUA attack). So he's managed
                  to keep his relevancy in the current Break/FUA focused meta
                  and we have decided to raise him up a tier because of that.
                </li>
                <li>
                  <strong>[Bronya]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. With the release of Feixiao, Bronya has added another
                  solid spot in top tier teams to her portfolio. She has been a
                  top pick for Boothill since his release and now serves as a
                  strong Robin substitute in Feixiao’s teams, allowing you to
                  use her elsewhere or if you don’t have her yet. Because of
                  that we feel she deserves the raise to T1 tier.
                </li>
                <li>
                  <strong>[Pela]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Pela still remains the champion of F2P and new players.
                  She's very universal, easy to build and easy to use, so we
                  have raised her to T1 which better showcases how good she is -
                  especially that she's still being commonly played with Acheron
                  after Jiaoqiu release.
                </li>
              </ul>
              <h6>
                <strong>05/10/2024</strong>
              </h6>
              <p>
                We have updated the Pure Fiction tier list based on the latest
                data (check{' '}
                <Link to="/star-rail/pure-fiction">Pure Fiction Analytics</Link>
                ), our own testing and community feedback. Here are the changes:
              </p>
              <ul>
                <li>
                  <strong>[Argenti]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Despite a bit underwhelming performance in the most recent
                  Pure Fiction phase, Argenti still is one of the best
                  characters you can bring into this mode - this phase was as
                  bad as it could be for him and he still reached nearly 30k
                  average score. After looking at his average performance in the
                  last 3 phases, so what's being taken into account for the
                  purpose of the tier list, and after testing him in various
                  teams, we think he deserves to sit in T0 alongside Herta and
                  Himeko.
                </li>
                <li>
                  <strong>[Blade]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Blade's average performance improved in the last few
                  phases as he found a new home alongside Jade (though he's not
                  played exclusively with her) - so we have decided to raise him
                  alongside Clara to the T1 tier.
                </li>
                <li>
                  <strong>[Firefly]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Lingsha has brought a second life to Firefly and enabled
                  her to shine in Pure Fiction - though Firefly is playing a
                  more 'supportive' role in that team, thanks to her insane
                  Weakness Implant ability via her Technique and Skill, allowing
                  Himeko and Lingsha to decimate the waves of enemies.
                </li>
                <li>
                  <strong>[Ruan Mei]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. The watchlist strikes again. After gathering more data
                  and doing more testing, we are pretty sure that Ruan Mei has
                  lost the battle of supremacy in Pure Fiction. Even if this
                  phase wasn't kind to the singing amplifier, she still sits
                  just behind Ruan Mei in average score, but when you look at
                  the performance across last few phases, Robin performs better
                  and is often the first choice in most archetypes - outside of
                  the Break one obviously. Ruan Mei is still an Apex tier
                  character due to her flexibility and ease of use, but Robin is
                  just something else in this mode, so she will remain the sole
                  T0 character (for now).
                </li>
                <li>
                  <strong>[Jing Yuan]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Time wasn't kind to our general. His performance continues
                  to drop in Pure Fiction as he fails to even reach the 30k
                  points threshold yet again (both in most recent phase and
                  across last 3 phases). Even if you do everything right when
                  playing him, he is outdamaged by newer and shinier damage
                  dealers, who also don't need to worry about LL using his
                  attack either too soon or too late.
                </li>
                <li>
                  <strong>[Moze]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Moze just can't keep up with Pure Fiction's pace and while
                  we initially thought he will have some niche uses, we were
                  mistaken. So he's being dropped to the Forgotten Tier where he
                  belongs.
                </li>
                <li>
                  <strong>[Serval]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. On paper, Serval looks like she has everything you want in
                  Pure Fiction from a DPS, but sadly the reality is cold and
                  brutal. While in the past she actually performed decently
                  well, being a 4-star release character took it's toll on her
                  and her performance continues to decrease, so we're dropping
                  her down a tier.
                </li>
              </ul>
              <p>
                When characters perform unexpectedly — either exceeding or
                falling short of expectations — we place them on our internal
                'Watch list'. This helps us avoid hasty tier adjustments and
                ensures that any changes we make are well-considered and
                accurate. We welcome your feedback! This are the characters
                being added to the watch list in PF mode:
              </p>
              <ul>
                <li>
                  <strong>[Jiaoqiu]</strong> - saying that the Foxian exceeded
                  our expectations is a understatement of the year. Not only he
                  jumped to the Apex tier post his release, but now he's
                  threatening Robin - his average scores were the highest in
                  this phase and he's nearly on par with Robin when you look at
                  the average data in last two phases. Still, we want to gather
                  one more dataset before deciding whether he should join Robin
                  in T0 (as he only was tested in last two phases).
                </li>
                <li>
                  <strong>[Feixiao]</strong> - Feixiao had a decent debut in
                  Pure Fiction despite being a Hunt character and we want to
                  observe her more before deciding whether to move her up or
                  keep her in T1.5.
                </li>
                <li>
                  <strong>[Seele]</strong> - Seele, outside of Shatter phases,
                  continues to struggle, but we know that in the right hands,
                  she has no issues reaching great scores even in 'neutral'
                  phases. We want to take a closer look on her the next phase,
                  before deciding what to do with her.
                </li>
                <li>
                  <strong>[Jingliu]</strong> - Jingliu is on the opposite side
                  of the spectrum compared to Seele and Feixiao. Her performance
                  continues to drop and she even failed to get on the
                  'scoreboard' in the most recent phase - her usage rate is
                  abysmal and so is her performance. Most likely in the next
                  phase she will drop to the Niche tier.
                </li>
              </ul>
              <h6>
                <strong>02/10/2024</strong>
              </h6>
              <p>
                We have added <strong>[Lingsha]</strong> to the tier list. No
                other changes have been made, but the Pure Fiction tier list
                will be updated in a few days - we're still gathering data for
                the current phase.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        <div className="tier-list-header">
          <p className="title">
            Tier List{' '}
            <span>
              (
              {(sortOption === 'moc' || sortOption === 'moc_special') &&
                'Memory of Chaos'}
              {(sortOption === 'pure' || sortOption === 'pure_special') &&
                'Pure Fiction'}
              {(sortOption === 'apo' || sortOption === 'apo_special') &&
                'Apocalyptic Shadow'}
              )
            </span>
          </p>
          <p className="sub-title">
            You're currently viewing the{' '}
            <strong>
              {(sortOption === 'moc' || sortOption === 'moc_special') &&
                'Memory of Chaos'}
              {(sortOption === 'pure' || sortOption === 'pure_special') &&
                'Pure Fiction'}
              {(sortOption === 'apo' || sortOption === 'apo_special') &&
                'Apocalyptic Shadow'}
            </strong>{' '}
            tier list. It shows{' '}
            {(sortOption === 'moc' || sortOption === 'moc_special') &&
              'how the character performs in the Memory of Chaos. Blast and single target damage are important here while AoE has niche uses against some bosses.'}
            {(sortOption === 'pure' || sortOption === 'pure_special') &&
              'how the character performs in the Pure Fiction. AoE is king here, followed by Blast while single target damage is mostly useless.'}
            {(sortOption === 'apo' || sortOption === 'apo_special') &&
              'how the character performs in the Apocalyptic Shadow. Single target and Break potential are heavily favored here.'}{' '}
            Use the switcher below to view a different tier list.
          </p>
        </div>
        <div className="eidolon-switcher">
          <div className="custom-switch">
            <Switch
              checked={eidolonSpecial}
              onChange={() => setEidolonSpecial(!eidolonSpecial)}
              onColor="#009EEC"
              offColor="#484950"
              className="switch"
            />
            Show 4★ characters at Eidolon 6 (E0 otherwise)
          </div>
        </div>
        {eidolonSpecial === true ? (
          <>
            <div className="tier-list-switcher">
              <div
                className={`option moc ${
                  (sortOption === 'moc' || sortOption === 'moc_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('moc')}
              >
                <h6>Memory of Chaos</h6>
              </div>
              <div
                className={`option pure ${
                  (sortOption === 'pure' || sortOption === 'pure_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('pure')}
              >
                <h6>Pure Fiction</h6>
              </div>
              <div
                className={`option apo ${
                  (sortOption === 'apo' || sortOption === 'apo_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('apo')}
              >
                <h6>Apocalyptic Shadow</h6>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tier-list-switcher">
              <div
                className={`option moc ${
                  (sortOption === 'moc' || sortOption === 'moc_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('moc_special')}
              >
                <h6>Memory of Chaos</h6>
              </div>
              <div
                className={`option pure ${
                  (sortOption === 'pure' || sortOption === 'pure_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('pure_special')}
              >
                <h6>Pure Fiction</h6>
              </div>
              <div
                className={`option apo ${
                  (sortOption === 'apo' || sortOption === 'apo_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('apo_special')}
              >
                <h6>Apocalyptic Shadow</h6>
              </div>
            </div>
          </>
        )}
        {(sortOption === 'pure' || sortOption === 'pure_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Pure Fiction</span> Analytics for historical data!
            </h4>
            <Link to="/star-rail/pure-fiction">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        {(sortOption === 'apo' || sortOption === 'apo_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Apocalyptic Shadow</span> Analytics for historical
              data!
            </h4>
            <Link to="/star-rail/apocalyptic-shadow">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        {(sortOption === 'moc' || sortOption === 'moc_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Memory of Chaos</span> Analytics for historical
              data!
            </h4>
            <Link to="/star-rail/memory-of-chaos">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        <div className="employees-filter-bar hsr with-color">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-hsr">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-header debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-header support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-header sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                </div>
                <h4 className="apex">Apex Characters</h4>
                <p>
                  Characters in this bracket are the cream of the crop. They can
                  do outrageous amount of damage, provide massive buffs (or
                  debuffs) or keep the team alive no matter what the enemy
                  throws at them. They simply can achieve the best, most
                  consistent and accessible results in their best teams,
                  allowing you to easily clear MoC and PF.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header apex">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Apex
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-0 first">
            <div className="tier-rating t-0">
              <span>T0</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-05">
            <div className="tier-rating t-05">
              <span>T0.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="meta" />
                  <FontAwesomeIcon icon={faStar} width="30" className="meta" />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="meta"
                  />
                </div>
                <h4 className="meta">Meta Characters</h4>
                <p>
                  Characters in this bracket make clearing MoC and PF a breeze,
                  but compared to the Apex characters they require more work
                  from your side. Either their team options are more
                  restrictive, they require bigger Relic investment or they
                  possess a notable weakness or downside. Still, they are all
                  great and won't disappoint you if you give them what they need
                  to shine.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header meta">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Meta
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-1">
            <div className="tier-rating t-1">
              <span>T1</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-15">
            <div className="tier-rating t-15">
              <span>T1.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-2">
            <div className="tier-rating t-2">
              <span>T2</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="niche" />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="niche"
                  />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="niche"
                  />
                </div>
                <h4 className="niche">Niche Characters</h4>
                <p>
                  Characters in this bracket are lacking in one or many fields
                  of their role in MoC or PF. These characters still possess the
                  ability to clear all content with the right build, endgame
                  blessing, team or enemy line-up but have much lower play rates
                  and success rates than Meta and Apex characters.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header niche">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-3">
            <div className="tier-rating t-3">
              <span>T3</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-4">
            <div className="tier-rating t-4">
              <span>T4</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon
                    icon={faFaceSadTear}
                    width="30"
                    className="forgotten"
                  />
                </div>
                <h4 className="forgotten">The Forgotten Ones</h4>
                <p>
                  Characters in this bracket struggle. A lot. Either their kits
                  simply don't work in the mode or they require a ton of
                  investment and love to be able to work. Just avoid them unless
                  your husbando or waifu landed here and you believe in the
                  power of love.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header forgotten">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> The Forgotten
                Ones <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-5">
            <div className="tier-rating t-5 te">
              <span>T5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Honkai: Star Rail | Prydwen Institute"
    description="The most popular tier list for Honkai: Star Rail that rates all available characters by their performance in Memory of Chaos, Pure Fiction and Apocalyptic Shadow. Find out who is the best!"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulHsrCharacter(
      sort: { fields: name, order: DESC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        element
        path
        tierCategory
        ratings {
          moc
          moc_special
          pure
          pure_special
          apo
          apo_special
        }
        tierListNew
        tierListTags
      }
    }
  }
`;
